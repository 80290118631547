<script lang="ts">
	import { closeOverlay, openConfirmOverlay } from "svelte-comps/overlay"
	import Logo from "../../assets/icons/LuxedoLogo.svg"
	import PageNav from "./PageNav.svelte"
	import { openFeedbackMenu, showContentSuggestions } from "svelte-comps/luxedo"
	import { pageLoader } from "../../modules/auth/onUserLogin"
	import { ReleaseNotes } from "../../release-notes"
	import * as Sentry from "@sentry/svelte"
	import { getCurrentUser } from "../../stores/UserStore"
	import { Toast } from "svelte-comps/toaster"
	import { AccountNavigator } from "./account/AccountStore"
	import { openWhatsNewOverlay } from "../reusable/overlays/whats-new"

	const oldSiteUrl = import.meta.env.VITE_OLD_API_URL
	const version = Object.keys(ReleaseNotes)[0]

	function openAccountOverlay() {
		AccountNavigator.open()
	}

	function askToRedirect() {
		openConfirmOverlay({
			prompt: ["Would you like to return to the previous version of the Luxedo web portal?"],
			buttons: {
				confirm: {
					text: "Continue",
					onClick: async () => {
						await pageLoader.onUserLogout()
						window.location.replace(oldSiteUrl)
					},
				},
			},
		})
	}

	function submitUserFeedback(feedback: string) {
		const eventId = Sentry.captureMessage("User Feedback")

		const user = getCurrentUser()

		const userFeedback = {
			event_id: eventId,
			name: `${user.firstname} ${user.lastname}`,
			email: `${user.email}`,
			comments: feedback,
		}

		Sentry.captureUserFeedback(userFeedback)
		Toast.success("Feedback submitted!")
		closeOverlay()
	}

	function openUserFeedback() {
		openFeedbackMenu(submitUserFeedback)
	}
</script>

<div id="page-base">
	<header id="page-header">
		<div class="flex-column">
			<a id="error-report-link" href="#" on:click|preventDefault={openUserFeedback}>Report Issue</a>
		</div>
		<a id="account-button" on:click|preventDefault={openAccountOverlay} href="#">Account</a>
	</header>
	<div id="sidebar-container">
		<div id="sidebar-top">
			<img id="luxedo-logo" src={Logo} alt="Luxedo Logo" />
			<PageNav />
		</div>
		<!-- <div class="nav-links"> -->
		<div id="help-and-info">
			<button id="help-link" class="link-button" on:click={openUserFeedback}> Help Desk </button>
			<button id="content-solutions" class="link-button" on:click={showContentSuggestions}>
				Content Solutions
			</button>
			<button id="whats-new-button" class="link-button" on:click={() => openWhatsNewOverlay()}>
				What's New (
				<span class="version-number">
					{version}
				</span>)
			</button>
			<a id="old-site-redirect" href={oldSiteUrl} on:click|preventDefault={askToRedirect}
				>Return to Legacy Portal</a
			>
		</div>

		<!-- </div> -->
	</div>
	<main>
		<slot />
	</main>
</div>

<style>
	#page-base {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-template-rows: auto 1fr;
		height: 100vh;
		width: 100vw;
		overflow: hidden;

		--nav-padding: 2rem;
	}

	#sidebar-top {
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow: hidden;
	}

	#luxedo-logo {
		margin-top: var(--nav-padding);
		width: 10rem;
	}

	#page-header {
		background-color: var(--color-bg);
		padding: 1rem 2rem 1rem 0;
		height: var(--header-height);
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: flex-end;
	}

	#account-button {
		line-height: 2rem;
		font-size: var(--h1);
		color: var(--color-main);
	}

	#error-report-link,
	#old-site-redirect {
		border: none;
	}

	#error-report-link {
		margin-right: 1rem;
	}

	#old-site-redirect {
		margin-bottom: 2rem;
	}

	#error-report-link:hover,
	#error-report-link:focus-visible,
	#old-site-redirect:hover,
	#old-site-redirect:focus-visible {
		text-decoration: underline;
	}

	#sidebar-container {
		grid-row: 1 / span 2;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}

	#help-and-info {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 2rem;
	}

	#whats-new-button {
		padding: 0;
		padding-bottom: 0.5rem;
		margin-right: 1rem;
	}

	#content-solutions,
	#help-link {
		padding: 0;
		margin-right: 1rem;
	}

	main {
		background-color: var(--color-bg);
		padding: 0 1rem;
		overflow: hidden;
	}

	.version-number {
		font-size: var(--h3);
		color: var(--color-main);
	}

	:global(.no-padding) {
		padding: 0;
	}

	:global(.overlay.whats-new) {
		height: 80vh;
	}
</style>
