import {
	DataHandlerDevice,
	DataHandlerFolder,
	DataHandlerMedia,
	ChargebeeMngr,
	DeviceSpotlightManager,
	Device,
} from "luxedo-data"

import { UserStore, getCurrentUser } from "../../stores/UserStore"
import { FileSystemMedia } from "../file-system/FileSystemMedia"
import { DeviceUpdateManager } from "../device-operation-managers/DeviceUpdateManager"
import { ProjectorPowerManager } from "luxedo-data"
import { DevicePlaybackManager } from "luxedo-data"
import { SuperscaleProgressManager } from "../progress-reporting/ProgressContext_Superscale"
import { LuxedoRPC } from "luxedo-rpc"
import { POLL_INTERVAL_SLOW } from "luxedo-rpc/src/backend/consts"
import { InitPageLoader } from "svelte-comps/auth"

export const pageLoader = new InitPageLoader(import.meta.env.VITE_API_URL, UserStore.set)

LuxedoRPC.backend.stopPolling()

export const ChargebeeManager = new ChargebeeMngr()

async function getTimetables() {
	const timetables = await LuxedoRPC.api.user.account_fetch_device_timetables()

	const devices = DataHandlerDevice.getMany()
	const mapped: { [index: string]: Device } = {}
	for (const dev of devices) {
		mapped[String(dev.id)] = dev
	}

	for (const [id, timetable] of Object.entries(timetables)) {
		mapped[id].timetableManager.handlePulledData(timetable)
	}
}

pageLoader.postInit(async () => {
	LuxedoRPC.backend.startPolling(POLL_INTERVAL_SLOW)
	DataHandlerMedia.bindAlterEndpoints()
	await ChargebeeManager.pullPlans(getCurrentUser())
	DataHandlerFolder.calculateAllFolderStorage()
	FileSystemMedia.initialize()
	await getTimetables()
	DeviceUpdateManager.initialize()
	ProjectorPowerManager.initialize()
	DevicePlaybackManager.initialize()
	DeviceSpotlightManager.initialize()
	// SuperscaleProgressManager.initialize()
})
