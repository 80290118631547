<script lang="ts">
	import AccountNav from "./AccountNav.svelte"
	import { ACCOUNT_ROUTES, AccountNavigator } from "./AccountStore"
	import { pageLoader } from "../../../modules/auth/onUserLogin"
	import { Toast } from "svelte-comps/toaster"

	let activeRoute: keyof typeof ACCOUNT_ROUTES

	AccountNavigator.subscribe(({ route }) => {
		activeRoute = route
	})

	async function logoutUser() {
		await pageLoader.onUserLogout()
		Toast.success("Successfully logged out. Redirecting to login page...")
		location.replace("/login")
	}
</script>

<div id="account-overlay">
	<menu id="overlay-sidemenu">
		<span class="flex-row">
			<h3>Account</h3>
			<button id="logout-button" class="link-button small" on:click={logoutUser}>Logout</button>
		</span>
		<AccountNav />
	</menu>
	<div class="open-account-menu">
		<svelte:component this={ACCOUNT_ROUTES[activeRoute]} />
	</div>
</div>

<style>
	#account-overlay {
		/* width: 100%; */
		height: 100%;
		width: 51rem;
		min-height: 32rem;
		background: var(--color-bg-gradient);
		display: flex;
		flex-direction: row;
	}

	#overlay-sidemenu .flex-row {
		justify-content: space-between;
		align-items: flex-end;
	}

	#overlay-sidemenu h3 {
		font-size: var(--h1);
		margin: 0;
	}

	#overlay-sidemenu {
		padding: 1rem 0 1rem 2rem;
		width: 14rem;
		position: relative;
	}

	#logout-button {
		color: var(--color-text);
	}

	#logout-button:hover,
	#logout-button:focus-visible {
		color: var(--color-main);
	}
	.open-account-menu {
		background-color: var(--color-bg);
		flex: 1;
		padding: 3rem 2rem 1rem 2rem;
	}

	.open-account-menu :global(.input-container) {
		flex-direction: column;
	}

	.open-account-menu :global(.input-container label) {
		width: 100%;
		text-align: left;
		font-size: var(--h3);
		color: var(--color-text);
		margin-bottom: 0.25rem;
		margin-left: 1rem;
	}

	.open-account-menu :global(.input-container input) {
		/* border: 1px solid var(--color-border); */
		background-color: var(--color-bg-darker);
		width: 14.5rem;
	}

	.open-account-menu :global(.input-container > input) {
		width: 16rem;
	}

	.open-account-menu :global(h3) {
		font-size: var(--h2);
		margin: 0 0 1rem 0;
	}
</style>
