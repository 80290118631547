<script lang="ts">
	import { onMount } from "svelte"
	import { ToggleSwitchAwait } from "svelte-comps/inputs"
	import { getCurrentUser } from "../../../stores/UserStore"
	import { LuxedoRPC } from "luxedo-rpc"
	import { DataHandlerUser } from "luxedo-data"
	import { DeviceUpdateManager } from "../../../modules/device-operation-managers/DeviceUpdateManager"

	// const PERFORMANCE_MODE = "performance-mode"

	let betaOptIn: boolean = false
	// let performanceMode: boolean = false

	async function toggleBetaVersion(doOptIn: boolean): Promise<void> {
		betaOptIn = doOptIn
		await LuxedoRPC.api.user.account_beta_opt_in(doOptIn ? 1 : 0)
		await DataHandlerUser.pull([])

		parseCurrentSettings()
		DeviceUpdateManager.initialize()
	}

	// async function togglePerformanceMode(setModeOn: boolean) {
	// 	performanceMode = setModeOn

	// 	localStorage.setItem(PERFORMANCE_MODE, String(performanceMode))
	// }

	function parseCurrentSettings() {
		const user = getCurrentUser()
		betaOptIn = user.hasPriv("beta_opt_in")

		// const performanceModeValue = localStorage.getItem(PERFORMANCE_MODE)
		// performanceMode = performanceModeValue === "true"
	}

	onMount(parseCurrentSettings)
</script>

<h3>Account Settings</h3>
<div class="toggle-switch-container">
	<span class="label">Try Beta Versions </span>
	<ToggleSwitchAwait isActive={betaOptIn} onUpdate={toggleBetaVersion} />
</div>

<!-- <div class="toggle-switch-container">
	<span class="label">Performance Mode </span>
	<ToggleSwitchAwait isActive={performanceMode} onUpdate={togglePerformanceMode} />
</div> -->

<style>
	.toggle-switch-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-basis: 50%;
		width: 50%;
		justify-content: space-between;
		padding: 0 1rem 0 0;
	}

	.label {
		color: var(--color-text);
		/* font-size: var(--h3); */
		margin-left: 1rem;
	}
</style>
