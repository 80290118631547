<script lang="ts">
	import { DataHandlerUser, type User } from "luxedo-data"
	import { asyncConfirmOverlay, closeOverlay } from "svelte-comps/overlay"
	import { UserStore } from "../../../stores/UserStore"
	import { LuxedoRPC } from "luxedo-rpc"
	import { Toast } from "svelte-comps/toaster"
	import PasswordInput from "../../reusable/input/PasswordInput.svelte"

	let currentUser: User
	let firstName: string
	let lastName: string
	let email: string
	let currentPassword: string
	let newPassword: string
	let confirmPassword: string

	UserStore.subscribe((user) => {
		currentUser = user
		firstName = user.firstname
		lastName = user.lastname
		email = user.email
	})

	async function updateContactInfo() {
		currentUser.firstname = firstName
		currentUser.lastname = lastName

		try {
			await DataHandlerUser.save(currentUser)
			Toast.success("Contact information updated!")
		} catch (e) {
			console.error("[ERROR] ", e)
			Toast.error("Unable to save contact data; please try again.")
		}
	}

	async function updateEmail() {
		if (email === currentUser.email) return

		await asyncConfirmOverlay({
			prompt: [
				"Changing your email will update your login credentials, requiring you to log in with the new email address. Do you wish to proceed?",
			],
			confirmText: "Yes",
			denyText: "Cancel",
			title: "Change Email?",
		})

		try {
			await LuxedoRPC.api.user.account_change_email(email)
			currentUser.email = email
			Toast.success("Account email changed successfully!")
		} catch (e) {
			console.error("An error occurred while changing email", e)
			if (e.statusCode === 409) return Toast.error("Email already in use.")
			if (e.statusCode === 403) return window.location.replace("/login")
		}
	}

	async function updatePassword() {
		if (!(newPassword === confirmPassword)) return Toast.error("Passwords must match.")

		try {
			await LuxedoRPC.api.user.account_change_password(currentPassword, newPassword)
			Toast.success("Password successfully updated!")
		} catch (e) {
			console.error("[ERROR] ", e)
			Toast.error("Unable to update password; please check the requirements and try again.")
		}
	}

	async function triggerSave() {
		if (email !== currentUser.email) await updateEmail()

		if (firstName !== currentUser.firstname || lastName !== currentUser.lastname) {
			await updateContactInfo()
		}

		if (!!currentPassword && !!newPassword) {
			await updatePassword()
		}

		closeOverlay()
	}
</script>

<form on:submit|preventDefault={triggerSave} action="#" autocomplete="off">
	<h3>Contact</h3>
	<div class="flex-row">
		<div class="input-container">
			<label for="first-name-input">First Name:</label>
			<input id="first-name-input" type="text" bind:value={firstName} autocomplete="off" />
		</div>
		<div class="input-container">
			<label for="last-name-input">Last Name:</label>
			<input id="last-name-input" type="text" bind:value={lastName} autocomplete="off" />
		</div>
	</div>
	<div class="flex-row">
		<div class="input-container">
			<label for="email-input">Email:</label>
			<input id="email-input" type="email" bind:value={email} autocomplete="username" />
		</div>
	</div>
	<h3 id="pw-heading">Change Password</h3>

	<div class="flex-row">
		<div class="input-container">
			<label for="current-password-input">Current Password:</label>
			<PasswordInput
				id="current-password-input"
				bind:value={currentPassword}
				autocomplete={false}
			/>
		</div>
		<div class="flex-column">
			<div class="input-container">
				<label for="new-password-input">New Password:</label>
				<PasswordInput id="new-password-input" bind:value={newPassword} autocomplete={true} />
				<!-- <input id="last-name-input" type="text" bind:value={newPassword} /> -->
			</div>
			<div class="input-container">
				<label for="confirm-password-input">Confirm Password:</label>
				<PasswordInput
					id="confirm-password-input"
					bind:value={confirmPassword}
					autocomplete={false}
				/>
			</div>
		</div>
	</div>
	<div class="button-container">
		<button type="button" class="link-button" on:click={() => closeOverlay()}>Cancel</button>
		<button type="submit" class="save-button">Save</button>
	</div>
</form>

<style>
	h3#pw-heading {
		margin-top: 2rem;
	}

	.flex-row {
		justify-content: space-between;
		gap: 1rem;
	}

	.flex-row:nth-of-type(even) {
		margin-top: 1rem;
	}

	.flex-row > .flex-column > .input-container:nth-of-type(2) {
		margin-top: 1rem;
	}

	.button-container {
		margin: 2rem 0 0 0;
		justify-content: flex-end;
	}

	.save-button {
		padding: 0.1rem 1rem;
	}
</style>
