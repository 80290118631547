/**
 * @file /datahandler/DataHandler.ts
 * @author Austin Day
 * An abstract class for loading and organizing data from a database on a server and loading it into the client
 * as Entry instances
 */

import { Entry } from "../entry/Entry"
import type { EntryID, IDMap, BundleOf, RawDataOf, DataHandlerListener } from "../types"

export abstract class DataHandler<EntryType extends Entry<any>> {
	abstract EntryClass: new (rawEntryData: RawDataOf<EntryType>) => EntryType

	/**
	 * An object which internally keeps track of all of the constructed data entries.
	 */
	protected declare entries: IDMap<EntryType>

	constructor() {
		this.entries = {}
	}

	//#region    ===========================			   Implementation 	 			==============================

	/**
	 * Central function which sends a request to the data server and returns the raw data
	 * If the data which comes back is not in the correct format, it should be reformatted at this step.
	 * @param specificInstances an array of desired entry IDs - if supported, including this will only pull the specified instances from the server.
	 */
	protected abstract fetch(specificInstances?: EntryID[]): Promise<BundleOf<EntryType>>

	/**
	 * Send raw data from one or several entries to the server
	 * If raw entry data needs to be reformatted before being sent to the server, it should happen here.
	 */
	protected abstract pushData(entryData: { [id: EntryID]: Partial<RawDataOf<EntryType>> }): Promise<void>

	/**
	 * Optional function which calls the backend to create a new database row corresponding to the entry data
	 * If implemented, it should be set up so that it resolves with the new ID assigned to this entry
	 * @param entryData
	 * @returns Promise resolving with the new ID that the backend has assigned to the entry
	 */
	protected async requestCreateEntry(entryData: Partial<RawDataOf<EntryType>>): Promise<EntryID> {
		throw "requestCreateEntry must be overloaded for createEntry to work."
	}

	/**
	 * Carries out deletion on the backend
	 * Overload to fit with your backend
	 * @param entry
	 */
	protected async requestDeleteEntry(entry: EntryType): Promise<void> {
		throw "requestDeleteEntry must be overloaded for deleteEntry to work."
	}

	//#endregion =====================================================================================================

	//#region    ===========================			 Entry Accessors 				==============================

	/**
	 * Standard way of accessing an entry instance.
	 * Note that Entries are persistent and have their data updated if they are modified by a pull, so references to
	 * 	an entry obtained with get() can be used repeatedly between pulls.
	 *
	 * @param entryID the ID of the entry to get the instance of
	 * @returns
	 */
	public get(entryID: EntryID): EntryType {
		return this.entries[entryID]
	}

	/**
	 * For use cases where you can't count on knowing when the latest data pull has occured
	 * Will return a proxy that is functionally identical to an entry instance itself, but has the advantage of always
	 * 	automatically pointing to the latest pulled-in version of the entry
	 * This should be used sparingly, as simply being very intentional about data pushes and pulls is the intended
	 * 	way which encourages better practices.
	 * @param entryID the ID of the entry to follow
	 * @returns Proxy to the entry
	 */
	public getProxyTo(entryID: EntryID): EntryType {
		return new Proxy<any>(this, {
			get(dh, property) {
				return dh.entries[entryID][property]
			},
		})
	}

	/**
	 * Get all entries within the datahandler, unless list of IDs is specified
	 */
	public getMany(instanceIDs?: Array<EntryID>, filterFn?: (entry: EntryType) => boolean): Array<EntryType> {
		let entries: Array<EntryType>
		if (instanceIDs) {
			entries = this.getListOfEntries(instanceIDs)
		} else {
			entries = Object.values(this.entries)
		}
		if (filterFn) entries = entries.filter(filterFn)
		return entries
	}

	private getListOfEntries(instanceIDs: Array<EntryID>): Array<EntryType> {
		const entries: Array<EntryType> = []
		for (const id of instanceIDs) {
			entries.push(this.get(id))
		}
		return entries
	}

	//#endregion =====================================================================================================

	//#region    ===========================			 Synchronization 				==============================

	private ongoingPull?: Promise<EntryID[]>

	/**
	 * Pull any new data from the server
	 * This is the intended way to pull instance data from the server
	 * @param specificInstances Optional array of specific IDs to pull down. Support must be implemented in fetch()
	 * @return An array of IDs of entries who have been reloaded
	 */
	async pull(specificInstances?: EntryID[]): Promise<EntryID[]> {
		// If instances are specified, do a full pull either way
		if (specificInstances) {
			return await this.pullData(specificInstances)
		}

		// If there's already an ongoing pull, just sync to it
		if (this.ongoingPull) {
			console.warn("Synchronizing with ongoing poll")
			return await this.ongoingPull
		}

		this.ongoingPull = this.pullData()

		// Wait for the results of what has changed
		const modifiedEntries = await this.ongoingPull

		// Make sure to clear the pull
		this.ongoingPull = undefined

		return modifiedEntries
	}

	/**
	 * Under the hood operation that is fetching data, checking for changes, and calling any listeners.
	 * @returns resolves with an array of entry IDs of entries which have changed
	 */
	private async pullData(specificInstances?: EntryID[]): Promise<EntryID[]> {
		const newData = await this.fetch(specificInstances) // fetch the new data from the server
		const modifiedEntries = this.handlePulledData(newData)
		return modifiedEntries
	}

	public handlePulledData(newData: BundleOf<EntryType>) {
		const modifiedEntries = [] as EntryID[] // to store the IDs of modified entries

		// compare the new data with the current data
		for (const idStr in newData) {
			const id: EntryID = parseInt(idStr)
			const currentEntry = id in this.entries ? this.entries[id] : null

			// Create a new entry if none exists
			if (!currentEntry) {
				const newEntry = new this.EntryClass(newData[id])
				this.entries[id] = newEntry

				modifiedEntries.push(id)
				continue
			}

			const entryModified = currentEntry.hash() != Entry.hashRawData(newData[id])

			// Set the latest data
			currentEntry.setRawData(newData[id])

			// if the data has changed
			if (entryModified) {
				modifiedEntries.push(id) // add the ID to the list of modified entries
			}
		}

		// Callback data change listeners (if needed)
		if (modifiedEntries.length > 0) {
			this.ondatachanged(modifiedEntries)
		}

		return modifiedEntries
	}

	/**
	 * Push updated entry data to the server and update the local data.
	 * @param entriesToPush - A list of IDs for entries that have changes to push
	 * @returns A promise that resolves when the data has been successfully pushed to the server.
	 */
	async push(entriesToPush: EntryType[]): Promise<void> {
		const pushBundle = {} as {
			[id: EntryID]: Partial<RawDataOf<EntryType>>
		}

		/* Iterate through listed entry IDs and prepare to export their data*/
		for (const entry of entriesToPush) {
			const id = entry.id

			if (!id || !(id in this.entries))
				throw ReferenceError(`Entry ID ${id} does not seem to exist in datahandler - did you mean to call createEntry?`)

			// Skip if there are no changes to push
			if (!entry.dirty) continue

			// Add the exportable data to our bundle
			pushBundle[id] = entry.toBundle()
		}

		// Send our changes to the server - If there's an issue with the push, this should throw and it is the caller's responsibility to deal with it.
		await this.pushData(pushBundle)

		// Pull changes in from server
		const modifiedIDs: EntryID[] = Object.keys(pushBundle).map((id) => parseInt(id))
		await this.pull(modifiedIDs)

		// Call any listeners
		this.ondatachanged(modifiedIDs)
	}

	/**
	 * Do a save (aka push) of a single entry instance that has been modified
	 * @param entry
	 */
	async save(entry: EntryType): Promise<void> {
		return await this.push([entry])
	}

	//#endregion =====================================================================================================

	//#region    ===========================		 Create/Delete interface 			==============================

	/**
	 * Call the backend to create a new entry given the provided data, and resolve with a new ID
	 * requestCreateEntry MUST be implemented in your class for this to work.
	 *
	 * @param entryOrPartialData a temporary instance of your Entry type OR partial data for the requestCreateEntry function
	 * @returns
	 */
	async createEntry(entryOrPartialData: EntryType | Partial<RawDataOf<EntryType>>): Promise<EntryID> {
		let creationData: Partial<RawDataOf<EntryType>>

		/* Get the partial data from an entry if passed */
		if (entryOrPartialData instanceof Entry) {
			creationData = entryOrPartialData.toBundle()
		} else {
			creationData = entryOrPartialData as Partial<RawDataOf<EntryType>>
		}

		const newId = await this.requestCreateEntry(creationData)

		await this.pull([newId])
		this.ondatachanged([newId])

		return newId
	}

	/**
	 * Function which calls the backend to delete the specified entry from the database
	 * requestDeleteEntry must be implemented for this to work
	 * @param entry
	 */
	async deleteEntry(entry: EntryType): Promise<void> {
		await this.requestDeleteEntry(entry)

		const entryId = entry.id!
		delete this.entries[entryId]

		await this.pull()
		this.ondatachanged([entryId])
	}

	//#endregion

	//#region    ===========================				Listeners					==============================

	/** Keep track of listeners that will fire when data is changed */
	private listeners = [] as DataHandlerListener[]

	/**
	 * Register a new listener to fire if there is a pull or push of new data
	 * @param handler
	 */
	addListener(handler: DataHandlerListener): void {
		this.listeners.push(handler)
	}

	removeListener(handler: DataHandlerListener): void {
		const handlerIndex = this.listeners.findIndex((h) => handler === h)
		if (handlerIndex > -1) this.listeners.splice(handlerIndex, 1)
	}

	/** Should be automatically called when a sync fires */
	protected ondatachanged(changedIDs: EntryID[]): void {
		for (const listener of this.listeners) {
			listener(changedIDs)
		}
	}

	//#endregion =====================================================================================================
}
