<script lang="ts">
	import ProjectorAdvancedInputs from "./inputs/ProjectorAdvancedInputs.svelte"

	import { DataHandlerDevice, DeviceRPi, type Device } from "luxedo-data"

	import { SelectedDeviceStore } from "../../../../../stores/SelectedDeviceStore"
	import { openConfirmOverlay } from "svelte-comps/overlay"
	import { Toast } from "svelte-comps/toaster"
	import { AdvancedSettingsController } from "./ProjectorAdvancedController"
	import AdvancedActions from "./actions/AdvancedActions.svelte"

	let device: Device
	let isOnline: boolean = false
	let isModified: boolean = false

	export let triggerSave = null

	SelectedDeviceStore.subscribe((dev) => {
		device = dev
	})

	AdvancedSettingsController.subscribe((ctx) => {
		isOnline = ctx.isOnline
		if (ctx.isOnline) isModified = ctx.isModified
		else isModified = false
	})

	async function saveModifiedSettings() {
		try {
			Toast.text(
				"Updating device settings. This may take a few minutes, and your device might restart during the process."
			)
			await AdvancedSettingsController.saveModifiedProperties()
			Toast.success("Device settings updated!")
		} catch (e) {
			console.error("Unable to save device settings", e)
			Toast.error("Unable to save device settings. Please refresh and try again...")
		}
	}

	/** Verifies the user wants to remove a device from their account */
	function verifyRemove() {
		openConfirmOverlay({
			prompt: [
				"Are you sure you want to remove this device from your account?",
				"This cannot be undone.",
			],
			buttons: {
				confirm: {
					text: "Yes",
					onClick: removeDevice,
				},
			},
		})
	}

	/** Removes the selected device from the logged in user's account */
	async function removeDevice() {
		try {
			await DataHandlerDevice.deleteEntry(device)
			const devices = DataHandlerDevice.getMany()
			if (devices.length) SelectedDeviceStore.set(devices[0])
			else SelectedDeviceStore.set(undefined)
			Toast.success("Device successfully removed from your account.")
		} catch (e) {
			console.error("Error removing device from user account: ", e)
			Toast.error(
				"An error occurred while trying to remove this device from your account. Please try again..."
			)
		}
	}

	$: isModified && (triggerSave = saveModifiedSettings)
	$: !isModified && (triggerSave = null)
</script>

<div class="advanced-options">
	{#if isOnline}
		<ProjectorAdvancedInputs />
		<AdvancedActions {device} />
	{:else}
		<p class="disconnected">⚠ Power on device to access advanced settings.</p>
	{/if}
	<div class="button-container always-shown">
		<button
			id="remove-device-button"
			class="outline-button"
			title="Remove Device from Account"
			on:click={verifyRemove}
		>
			Remove from Account
		</button>
	</div>
</div>

<style>
	.advanced-options {
		display: flex;
		flex-direction: column;
		/* justify-content: space-between; */
		width: 100%;
		height: 100%;
	}

	.disconnected {
		margin-bottom: 0;
		color: var(--color-main);
	}

	#remove-device-button {
		border-color: var(--color-error);
	}

	#remove-device-button:hover,
	#remove-device-button:focus-visible {
		background-color: var(--color-error);
	}

	.button-container {
		flex-direction: column;
		align-items: flex-start;
		margin-top: 1rem;
	}

	.always-shown {
		margin-top: auto;
	}
</style>
