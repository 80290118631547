<script lang="ts">
	import { ShowLibraryController, type ShowLibraryViewOption } from "./ShowLibraryController"
	import ShowLibraryHeading from "./show-library-heading/ShowLibraryHeading.svelte"
	import { DataHandlerDevice, type Device, type Lightshow, type Scene } from "luxedo-data"
	import DeviceFilter from "../../reusable/device-filter/DeviceFilter.svelte"
	import LightshowEditor from "./lightshow-editor/LightshowEditor.svelte"
	import ProjectList from "./ProjectList.svelte"
	import LightshowList from "./LightshowList.svelte"
	import { onMount } from "svelte"

	let viewType: ShowLibraryViewOption = "Scenes"
	let isEditingLightshow: boolean = false

	let filterUpdater = 0 // use to force filter update
	let searchInput: string
	let selectedTags: Array<number>

	let libraryWidth: number
	let tileWidth: number // used to set css var to make scene tiles appear aligned

	let deviceFilter: Device

	let runFilter: (content: Array<Scene | Lightshow>) => Array<Scene | Lightshow> = (content) =>
		content
	let runOrder: (content: Array<Scene | Lightshow>) => Array<Scene | Lightshow> = (content) =>
		content

	ShowLibraryController.subscribe((ctx) => {
		viewType = ctx.view
		isEditingLightshow = ctx.isEditingLightshow
		deviceFilter = ctx.deviceFilter
		onFilterSelectionUpdate(ctx.filterSelection.filter, ctx.filterSelection.order)
	})

	/** Runs the filter methods, which are updated in onFilterSelectionUpdate */
	function filterOperation(content: Array<Scene | Lightshow>) {
		let filteredContent = runFilter(content)
		let orderedContent = runOrder(filteredContent)
		return orderedContent
	}

	/** Set the filter function according to the new filter selection. */
	function onFilterSelectionUpdate(filter: string, order: string) {
		type Content = Array<Scene | Lightshow>

		switch (filter) {
			case "Name":
				runFilter = (content: Content) => content.sort((a, b) => a.name.localeCompare(b.name))
				break
			case "Last Modified":
				runFilter = (content: Content) =>
					content.sort((a, b) => b.updated_at.getUTCSeconds() - a.updated_at.getUTCSeconds())
				break
			case "Projector":
				runFilter = (content: Content) =>
					content.sort((a, b) => a.target_device_id - b.target_device_id)
				break
			case "Tags":
				runFilter = (content: Content) => content.sort((a, b) => a.tags.length - b.tags.length)
				break
			default:
				runFilter = (content) => content
				break
		}

		switch (order) {
			case "Descending":
				runOrder = (content: Content) => content
				break
			case "Ascending":
			default:
				runOrder = (content: Content) => content.reverse()
				break
		}

		filterUpdater++
	}

	function calculateTileWidth(width: number) {
		const TILE_WIDTH_PREFFERED = 128
		const TILE_MARGIN = 33

		const tileAmountPerRow = Math.floor(width / (TILE_WIDTH_PREFFERED + TILE_MARGIN))
		const newTileWidth = Math.floor(width / tileAmountPerRow) - TILE_MARGIN

		tileWidth = newTileWidth
	}

	onMount(() => {
		ShowLibraryController.setDeviceFilter(undefined)
	})

	$: calculateTileWidth(libraryWidth)
</script>

<div id="show-library-container">
	{#if isEditingLightshow}
		<LightshowEditor />
	{/if}
	<div id="library-wrapper">
		<div id="show-library" bind:clientWidth={libraryWidth} style="--tile-width: {tileWidth}px;">
			<ShowLibraryHeading bind:searchInput bind:selectedTags />
			{#if viewType === "Scenes"}
				<ProjectList {searchInput} {filterOperation} {filterUpdater} tagFilter={selectedTags} />
			{:else}
				<LightshowList {searchInput} {filterOperation} {filterUpdater} tagFilter={selectedTags} />
			{/if}
		</div>
		<div id="library-right">
			{#if DataHandlerDevice.getMany().length}
				<DeviceFilter
					selectedDevice={deviceFilter}
					onSetDeviceFilter={ShowLibraryController.setDeviceFilter}
				/>
			{/if}
		</div>
	</div>
</div>

<style>
	#show-library :global(.empty-indicator) {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: var(--color-text);
	}

	#show-library :global(.empty-text) {
		/* width: 60%; */
		text-align: center;
	}

	#show-library :global(.empty-indicator svg) {
		width: 8rem;
		height: 8rem;
	}

	#show-library :global(.empty-indicator .svg-fill) {
		fill: var(--color-text);
	}

	#show-library :global(.create-quick-action) {
		background-color: unset;
		box-shadow: unset;
		padding: unset;
		color: var(--color-main);
		transform: unset;
	}

	#show-library :global(.create-quick-action:hover),
	#show-library :global(.create-quick-action:focus-visible) {
		color: var(--color-text-light);
	}

	#show-library-container {
		height: calc(100vh - var(--header-height));
		display: flex;
		flex-direction: column;
	}

	#library-wrapper {
		display: flex;
		flex-direction: row;
		overflow: hidden;
		height: 100%;
	}

	#show-library {
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
		height: 100%;
		flex-grow: 1;
		padding-right: 0.25rem;
		margin-right: 0.5rem;
	}

	#show-library :global(#library-list) {
		flex-grow: 1;
	}

	#show-library :global(.library-list) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	#show-library :global(.library-list .tile-container) {
		margin: 1rem;
	}
</style>
